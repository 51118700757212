import React from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

import { LinkButton, SubmitButton, ButtonGroup } from "../common/Buttons";
import { DRINK_TYPE_COFFEE } from "../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: "0",
    minWidth: 200,
  },
  smallInputControl: {
    margin: theme.spacing(1),
    maxWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UpdateDrinkForm = ({ defaultValues, onSubmit, cancelPath }) => {
  const {
    preparationTypes,
    variants,
    milkTypes,
    flavorings,
    drinkTypes,
    sweeteners,
  } = useSelector((state) => state.app.meta);

  console.log(defaultValues);
  const [isLoading, setIsLoading] = React.useState(true);

  const [masterDrinkType, setMasterDrinkType] = React.useState(
    defaultValues ? defaultValues.drinkType : DRINK_TYPE_COFFEE
  );
  const { control, handleSubmit, setValue } = useForm();
  const classes = useStyles();

  React.useEffect(() => {
    // setup some values here
    // console.log(defaultValues.drinkType);
    setIsLoading(false);
  }, []);

  const onDrinkTypeChange = (e) => {
    const changedDrinkType = e.target.value;

    // set values for all the dynamic selections to "empty"
    setValue("preparationType", "");
    setValue("variant", "");
    setValue("variantVolume", "");
    setValue("milkType", "");
    setValue("milkVolume", "");
    setValue("flavoring", "");
    setValue("flavoringVolume", "");
    setValue("sweetener", "");
    setValue("sweetenerVolume", "");
    // update the master drink type so the options change
    setMasterDrinkType(changedDrinkType);

    // now set the values for the dynamic options to the first item in the respective lists
  };

  if (isLoading) {
    return null;
  }

  if (!isLoading && masterDrinkType) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Controller
          name="name"
          control={control}
          defaultValue={defaultValues ? defaultValues.name : ""}
          render={({ field }) => (
            <TextField
              {...field}
              label="Name"
              variant="filled"
              helperText="Provide a descriptive name for the drink"
              fullWidth
              required
              autoFocus
            />
          )}
        />

        <FormGroup>
          <Controller
            name="isForMe"
            control={control}
            defaultValue={defaultValues ? defaultValues.isForMe : true}
            render={({ field }) => (
              <FormControlLabel
                label="This drink is for me"
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </FormGroup>

        <FormGroup>
          <Controller
            name="drinkType"
            control={control}
            defaultValue={
              defaultValues ? defaultValues.drinkType : masterDrinkType
            }
            render={({ field }) => (
              <FormControl className={classes.formControl} variant="filled">
                <InputLabel id="select-drink-type">Drink Type</InputLabel>
                <Select
                  {...field}
                  labelId="select-drink-type"
                  label="Drink Type"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    onDrinkTypeChange(e);
                  }}
                >
                  {Object.entries(drinkTypes).map((drinkType) => {
                    return (
                      <MenuItem key={drinkType[0]} value={drinkType[0]}>
                        {drinkType[1].name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Controller
            name="preparationType"
            control={control}
            defaultValue={defaultValues ? defaultValues.preparationType : ""}
            render={({ field }) => (
              <FormControl className={classes.formControl} variant="filled">
                <InputLabel id="select-preparation-type">
                  Preparation Type
                </InputLabel>
                <Select
                  {...field}
                  size="small"
                  labelId="select-preparation-type"
                  label="Preparation Type"
                  fullWidth
                  required
                >
                  <MenuItem key={0} value={""}>
                    None
                  </MenuItem>
                  {drinkTypes[masterDrinkType].preparationTypes.map(
                    (prepType) => {
                      return (
                        <MenuItem key={prepType} value={prepType}>
                          {preparationTypes[prepType].name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            )}
          />
        </FormGroup>

        <div>
          <Controller
            name="variant"
            control={control}
            defaultValue={defaultValues ? defaultValues.variant : ""}
            render={({ field }) => (
              <FormControl className={classes.formControl} variant="filled">
                <InputLabel id="select-variant">
                  {masterDrinkType === DRINK_TYPE_COFFEE
                    ? "Bean Type"
                    : "Tea Type"}
                </InputLabel>
                <Select
                  {...field}
                  size="small"
                  labelId="select-variant"
                  label={
                    masterDrinkType === DRINK_TYPE_COFFEE
                      ? "Bean Type"
                      : "Tea Type"
                  }
                  required
                >
                  {drinkTypes[masterDrinkType].variants.map((variant) => {
                    return (
                      <MenuItem key={variant} value={variant}>
                        {variants[variant].name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="variantVolume"
            control={control}
            defaultValue={defaultValues ? defaultValues.variantVolume : ""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Volume"
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">g</InputAdornment>
                  ),
                }}
                className={classes.smallInputControl}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="milkType"
            control={control}
            defaultValue={defaultValues ? defaultValues.milkType : ""}
            render={({ field }) => (
              <FormControl className={classes.formControl} variant="filled">
                <InputLabel id="select-milk">Milk Type</InputLabel>
                <Select {...field} size="small" labelId="select-variant">
                  <MenuItem key={0} value={""}>
                    None
                  </MenuItem>
                  {Object.entries(milkTypes).map((mt) => {
                    return (
                      <MenuItem key={mt[0]} value={mt[0]}>
                        {mt[1].name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="milkVolume"
            control={control}
            defaultValue={defaultValues ? defaultValues.milkVolume : ""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Volume"
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">oz</InputAdornment>
                  ),
                }}
                className={classes.smallInputControl}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="sweetener"
            control={control}
            defaultValue={defaultValues ? defaultValues.sweetener : ""}
            render={({ field }) => (
              <FormControl className={classes.formControl} variant="filled">
                <InputLabel id="select-sweetener">Sweetener</InputLabel>
                <Select
                  {...field}
                  size="small"
                  labelId="select-sweetener"
                  label="Sweetener"
                >
                  <MenuItem key={0} value={""}>
                    None
                  </MenuItem>
                  {Object.entries(sweeteners).map((sweet) => {
                    return (
                      <MenuItem key={sweet[0]} value={sweet[0]}>
                        {sweet[1].name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="sweetenerVolume"
            control={control}
            defaultValue={defaultValues ? defaultValues.sweetenerVolume : ""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Volume"
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">g</InputAdornment>
                  ),
                }}
                className={classes.smallInputControl}
              />
            )}
          />
        </div>

        {masterDrinkType === DRINK_TYPE_COFFEE && (
          <div>
            <Controller
              name="flavoring"
              control={control}
              defaultValue={defaultValues ? defaultValues.flavoring : ""}
              render={({ field }) => (
                <FormControl className={classes.formControl} variant="filled">
                  <InputLabel id="select-flavoring">Flavoring</InputLabel>
                  <Select
                    {...field}
                    size="small"
                    labelId="select-flavoring"
                    label="Flavoring"
                  >
                    <MenuItem key={0} value={""}>
                      None
                    </MenuItem>
                    {Object.entries(flavorings).map((flav) => {
                      return (
                        <MenuItem key={flav[0]} value={flav[0]}>
                          {flav[1].name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              name="flavoringVolume"
              control={control}
              defaultValue={defaultValues ? defaultValues.flavoringVolume : ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Volume"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">oz</InputAdornment>
                    ),
                  }}
                  className={classes.smallInputControl}
                />
              )}
            />
          </div>
        )}

        <div>
          <FormGroup>
            <Controller
              name="notes"
              control={control}
              defaultValue={defaultValues ? defaultValues.notes : ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Notes"
                  variant="filled"
                  helperText="Notes about this drink e.g. preparation details etc."
                  fullWidth
                />
              )}
            />
          </FormGroup>
        </div>
        <ButtonGroup>
          <LinkButton buttonText="Cancel" path={cancelPath} variant="text" />
          <SubmitButton />
        </ButtonGroup>
      </form>
    );
  }
};

export default UpdateDrinkForm;
