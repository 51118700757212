import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const Image = ({ url, altText }) => {
  return (
    <img src={url} alt={altText} style={{ maxWidth: "100%", height: "auto" }} />
  );
};

const ImageContainer = ({
  imageUrl,
  imageAltText,
  imageLinkUrl,
  attribution,
  children,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {imageLinkUrl && (
        <Link href={imageLinkUrl} target="_blank">
          <Image url={imageUrl} altText={imageAltText} />
        </Link>
      )}
      {!imageLinkUrl && <Image url={imageUrl} altText={imageAltText} />}
      {attribution && (
        <Typography variant="body2" component="p">
          {attribution}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default ImageContainer;
