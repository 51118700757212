import ImageContainer from "../common/ImageContainer";

const CoffeeBrewingMethods = () => {
  return (
    <ImageContainer
      attribution="Image courtesy of HomeGrounds.co. Click the image to visit the article."
      imageAltText="Complete Guide to Brewing Methods"
      imageLinkUrl="https://www.homegrounds.co/the-complete-guide-to-coffee-brewing-methods/"
      imageUrl="https://www.homegrounds.co/wp-content/uploads/2019/08/COFFEE-BREWING-METHODS-INFO.png"
    />
  );
};

export default CoffeeBrewingMethods;
