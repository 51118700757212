import { Link as RouterLink } from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalCafeOutlinedIcon from "@material-ui/icons/LocalCafeOutlined";
import EmojiFoodBeverageOutlinedIcon from "@material-ui/icons/EmojiFoodBeverageOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";

import { makeStyles } from "@material-ui/core/styles";

import {
  PATH_DASHBOARD,
  PATH_COFFEE_BREWING_METHODS,
  PATH_TEA_VARIETIES,
  PATH_SIGN_OUT,
  PATH_CREATE_DRINK,
  PATH_CREATE_MACHINE,
  PATH_DRINK_LOG,
  PATH_MANAGE_DRINKS,
} from "../paths";

export const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  anchorLink: {
    // color: "#ffffff",
    color: "rgba(0, 0, 0, 0.54)",
    textDecoration: "none",
    fontSize: "0.9rem",
  },
  listItemIcon: {
    minWidth: "36px",
  },
}));

const MainNavigationItems = () => {
  const classes = useStyles();
  return (
    <List>
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <HomeOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_DASHBOARD} className={classes.anchorLink}>
              Dashboard
            </RouterLink>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <SettingsOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_DASHBOARD} className={classes.anchorLink}>
              Settings
            </RouterLink>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <ListAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_DRINK_LOG} className={classes.anchorLink}>
              Drink Log
            </RouterLink>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <FormatListBulletedOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_MANAGE_DRINKS} className={classes.anchorLink}>
              Manage Drinks
            </RouterLink>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <LocalCafeOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_CREATE_DRINK} className={classes.anchorLink}>
              Add Drink
            </RouterLink>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <TouchAppOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_CREATE_MACHINE} className={classes.anchorLink}>
              Add Machine
            </RouterLink>
          }
        />
      </ListItem>

      <Divider />

      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <LocalCafeOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink
              to={PATH_COFFEE_BREWING_METHODS}
              className={classes.anchorLink}
            >
              Coffee Brewing
            </RouterLink>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <EmojiFoodBeverageOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_TEA_VARIETIES} className={classes.anchorLink}>
              Tea Varieties
            </RouterLink>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon className={classes.listItemIcon}>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <RouterLink to={PATH_SIGN_OUT} className={classes.anchorLink}>
              Sign Out
            </RouterLink>
          }
        />
      </ListItem>
    </List>
  );
};

export default MainNavigationItems;
