import React from "react";
import { useForm, Controller } from "react-hook-form";

import TextField from "@material-ui/core/TextField";

import ValidPasswordDisplay from "../common/ValidPasswordDisplay";
import { SubmitButton } from "../common/Buttons";

const RegisterForm = (props) => {
  const { onSubmit, isWorking } = props;
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    watch,
  } = useForm({ mode: "onChange" });

  const watchedFields = watch(["password", "passwordConfirm"]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        rules={{ required: "Please enter your name" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Your Name"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="name"
            autoFocus
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: "Please enter your email" }}
        render={({
          value,
          name,
          field: { onChange },
          fieldState: { error },
        }) => (
          <TextField
            label="Email"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="email"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{ required: "Please enter a password" }}
        render={({
          value,
          name,
          field: { onChange },
          fieldState: { error },
        }) => (
          <TextField
            type="password"
            label="Password"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="new-password"
          />
        )}
      />
      <Controller
        name="passwordConfirm"
        control={control}
        defaultValue=""
        rules={{ required: "Re-enter the password" }}
        render={({
          value,
          name,
          field: { onChange },
          fieldState: { error },
        }) => (
          <TextField
            type="password"
            label="Confirm Password"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="new-password"
          />
        )}
      />
      <ValidPasswordDisplay
        value={watchedFields[0]}
        confirmValue={watchedFields[1]}
      />
      <div style={{ textAlign: "center", margin: "16px 0" }}>
        <SubmitButton
          variant="contained"
          color="primary"
          isWorking={isWorking}
          isDisabled={!isDirty || !isValid || isWorking}
          buttonText="Register"
        />
      </div>
    </form>
  );
};

export default RegisterForm;
