import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { reduxSetFeedbackMessage } from "../store/app-store";
import { fetchDrink, createDrink, updateDrink } from "../api/api-requests";
import PageHeading from "../common/PageHeading";
import Spinner from "../common/Spinner";
import { PATH_DASHBOARD, PATH_MANAGE_DRINKS } from "../paths";
import UpdateDrinkForm from "./UpdateDrinkForm";

const UpdateDrink = () => {
  const reduxDispatch = useDispatch();
  const { drinkId } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isWorking, setIsWorking] = React.useState(true);
  const [redirect, setRedirect] = React.useState(null);
  const [defaultFormValues, setDefaultFormValues] = React.useState(null);

  React.useEffect(() => {
    const doFetchDrink = async () => {
      try {
        const drink = await fetchDrink(drinkId);
        setDefaultFormValues(drink);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsLoading(false);
      }
    };

    if (drinkId) {
      doFetchDrink();
    } else {
      setIsLoading(false);
    }
  }, [drinkId, reduxDispatch]);

  const onAddNewSubmit = (formData) => {
    const doAddNew = async () => {
      try {
        setIsWorking(true);
        await createDrink(formData); // TODO: get the newly created id
        reduxDispatch(
          reduxSetFeedbackMessage("Drink added successfully", false)
        );
        setRedirect(PATH_DASHBOARD);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };
    console.log(formData);
    doAddNew();
  };

  const onUpdateSubmit = (formData) => {
    const doUpdate = async () => {
      try {
        setIsWorking(true);
        await updateDrink(drinkId, formData);
        reduxDispatch(
          reduxSetFeedbackMessage("Drink updated successfully", false)
        );
        setRedirect(PATH_MANAGE_DRINKS);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    doUpdate();
  };

  if (!isWorking && redirect) {
    return <Redirect to={redirect} />;
  }

  if (!isLoading) {
    return (
      <>
        <PageHeading>{drinkId ? "Update" : "Add"} Drink</PageHeading>
        <UpdateDrinkForm
          onSubmit={drinkId ? onUpdateSubmit : onAddNewSubmit}
          cancelPath={drinkId ? PATH_MANAGE_DRINKS : PATH_DASHBOARD}
          defaultValues={defaultFormValues}
        />
      </>
    );
  }

  return <Spinner />;
};

export default UpdateDrink;
