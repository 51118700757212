import { ListItemSecondaryAction } from "@material-ui/core";
import { API } from "aws-amplify";

// import { getUserTimezone } from "./date-helpers";

export const apiName = "protectedEndpoint";

export const pathPrefixAccount = "/accounts";
export const pathPrefixDrinks = "/drinks";
export const pathPrefixMachines = "/machines";
export const pathPrefixDashboard = "/dashboard";
export const pathPrefixConsumed = "/consumed";

// helper methods:
const doPost = (url, formData) => {
  return API.post(apiName, url, { body: formData });
};

const doPatch = (url, formData) => {
  return API.patch(apiName, url, { body: formData });
};

// const doPut = (url, formData) => {
//   return API.put(apiName, url, { body: formData });
// };

const doGet = (url, querystringParams = null) => {
  return API.get(apiName, url, { queryStringParameters: querystringParams });
};

const doDelete = (url) => {
  return API.del(apiName, url);
};

// endpoints:
export const createAccount = () => {
  return doPost(pathPrefixAccount);
};

export const fetchAccount = () => {
  return doGet(pathPrefixAccount);
};

// Dashboard:
export const fetchDashboardData = (statsStartDate) => {
  return doGet(pathPrefixDashboard, { startDate: statsStartDate });
};

// Drinks:
export const createDrink = (drinkData) => {
  return doPost(pathPrefixDrinks, drinkData);
};

export const updateDrink = (drinkId, drinkData) => {
  return doPatch(`${pathPrefixDrinks}/${drinkId}`, drinkData);
};

export const deleteDrink = (drinkId) => {
  return doDelete(`${pathPrefixDrinks}/${drinkId}`);
};

export const fetchDrink = (drinkId) => {
  return doGet(`${pathPrefixDrinks}/${drinkId}`);
};

export const fetchAllDrinks = () => {
  return doGet(pathPrefixDrinks);
};

export const consumeDrink = (drinkId, consumeData = {}) => {
  return doPost(`${pathPrefixDrinks}/${drinkId}/consume`, consumeData);
};

export const deleteConsumedDrink = (itemId) => {
  return doDelete(`${pathPrefixConsumed}/${itemId}`);
};

export const fetchDrinkLog = (startDate, endDate = null) => {
  return doGet(`${pathPrefixDrinks}/log`, {
    startDate: startDate,
    endDate: endDate,
    // tz: getUserTimezone(),
  });
};

// Machines:
export const createMachine = (machineData) => {
  return doPost(pathPrefixMachines, machineData);
};

export const updateMachine = (machineId, machineData) => {
  return doPatch(`${pathPrefixMachines}/${machineId}`, machineData);
};

export const deleteMachine = (machineId) => {
  return doDelete(`${pathPrefixMachines}/${machineId}`);
};

export const fetchMachine = (machineId) => {
  return doGet(`${pathPrefixMachines}/${machineId}`);
};

export const fetchAllMachines = () => {
  return doGet(pathPrefixMachines);
};
