import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Typography from "@material-ui/core/Typography";

import { LinkButton } from "./Buttons";
import { CoffeeAvatar, TeaAvatar } from "./Avatars";
import { DRINK_TYPE_COFFEE, DRINK_TYPE_TEA } from "../constants";
import { PATH_UPDATE_DRINK, PATH_PARAM_DRINK_ID } from "../paths";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    margin: 0,
    paddingTop: "6px",
    paddingBottom: "6px",
    fontSize: "1.1rem",
  },
  cardActions: {
    margin: 0,
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
  },
}));

const DrinkCardActions = ({ drink }) => {
  const classes = useStyles();
  return (
    <CardActions className={classes.cardActions} disableSpacing>
      <LinkButton
        buttonText="Update"
        variant="text"
        path={PATH_UPDATE_DRINK.replace(PATH_PARAM_DRINK_ID, drink.id)}
        buttonIcon={<EditOutlinedIcon />}
      />
      <LinkButton
        buttonText="Delete"
        color="secondary"
        variant="text"
        path={PATH_UPDATE_DRINK.replace(PATH_PARAM_DRINK_ID, drink.id)}
        buttonIcon={<DeleteOutlineOutlinedIcon />}
      />
    </CardActions>
  );
};

const DrinkCardContents = ({ drink }) => {
  const classes = useStyles();
  return (
    <CardContent className={classes.cardContent}>
      {drink.drinkType === DRINK_TYPE_COFFEE && <CoffeeDetails drink={drink} />}
      {drink.drinkType === DRINK_TYPE_TEA && <TeaDetails drink={drink} />}
    </CardContent>
  );
};

const CoffeeDetails = ({ drink }) => {
  return <Typography variant="body2">Coffee details here</Typography>;
};

const TeaDetails = ({ drink }) => {
  return <Typography variant="body2">Tea details here</Typography>;
};

const DrinkCard = ({ drink, isForManage = false }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        avatar={
          <>
            {drink.drinkType === DRINK_TYPE_COFFEE && <CoffeeAvatar />}
            {drink.drinkType === DRINK_TYPE_TEA && <TeaAvatar />}
          </>
        }
        title={
          <Typography
            variant="h5"
            component="h5"
            className={classes.cardHeader}
          >
            {drink.name}
          </Typography>
        }
        subheader={drink.description}
        className={classes.cardHeader}
      />
      {isForManage && (
        <>
          <DrinkCardContents drink={drink} />
          <DrinkCardActions drink={drink} />
        </>
      )}
    </Card>
  );
};

export default DrinkCard;
