import { STATE_WORKING } from "./store-constants";

import APP_META_DATA from "../data/app-data";

const APP_SET_FEEDBACK_MESSAGE = "APP_SET_FEEDBACK_MESSAGE";
const APP_CLEAR_FEEDBACK_MESSAGE = "APP_CLEAR_FEEDBACK_MESSAGE";
const APP_SET_WORKING_STATE = "APP_SET_WORKING_STATE";

const APP_INITIAL_STATE = {
  workingState: null,
  feedbackMessage: null,
  meta: APP_META_DATA,
};

export const reduxSetWorkingState = (workingState = STATE_WORKING) => {
  return {
    type: APP_SET_WORKING_STATE,
    payload: {
      workingState: workingState,
    },
  };
};

export const reduxSetFeedbackMessage = (message, isError) => {
  return {
    type: APP_SET_FEEDBACK_MESSAGE,
    payload: {
      feedbackMessage: {
        message: message,
        isError: isError,
      },
    },
  };
};

export const reduxClearFeedbackMessage = () => {
  return {
    type: APP_CLEAR_FEEDBACK_MESSAGE,
    payload: {
      feedbackMessage: null,
    },
  };
};

export const appReducer = (state = APP_INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_CLEAR_FEEDBACK_MESSAGE:
    case APP_SET_FEEDBACK_MESSAGE:
    case APP_SET_WORKING_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
