const APP_META_DATA = {
  preparationTypes: {
    Aero: { id: "Aero", name: "AeroPress" },
    Espresso: { id: "Espresso", name: "Espresso Machine" },
    FrenchPress: { id: "FrenchPress", name: "French Press" },
    Instant: { id: "Instant", name: "Instant" },
    Stovetop: { id: "Stovetop", name: "Moka Pot / Stovetop" },
    Chemex: { id: "Chemex", name: "Pour Over / Drip (Chemex)" },
    Cone: { id: "Cone", name: "Pour Over / Drip (Cone)" },
    Drip: { id: "Drip", name: "Pour Over / Drip (Other)" },
    Softbrew: { id: "Softbrew", name: "SoftBrew" },
    Siphon: { id: "Siphon", name: "Vacuum: Siphon" },
    
    TeaBag: { id: "TeaBag", name: "Tea Bag" },
    LooseLeaf: { id: "LooseLeaf", name: "Loose Leaf" },
  },
  variants: {
    Espresso: { id: "Espresso", name: "Espresso" },
    Dark: { id: "Dark", name: "Dark Roast" },
    French: { id: "French", name: "French Roast" },
    Decaf: { id: "Decaf", name: "Decaffeinated" },

    Black: { id: "Black", name: "Black Tea" },
    Green: { id: "Green", name: "Green Tea" },
    Oolong: { id: "Oolong", name: "Oolong Tea" },
    Matcha: { id: "Matcha", name: "Matcha Tea" },
    Herbal: { id: "Herbal", name: "Herbal Tea" },
    White: { id: "White", name: "White Tea" },
    Blend: { id: "Blend", name: "Blends (Fruit, herbs, spices etc.)" },
  },
  milkTypes: {
    Whole: { id: "Whole", name: "Whole Milk" },
    Two: { id: "Two", name: "2% Milk" },
    One: { id: "One", name: "1% Milk" },
    Free: { id: "Free", name: "Fat Free Milk" },
    Soy: { id: "Soy", name: "Soy Milk" },
    Almond: { id: "Almond", name: "Almond Milk" },
    Coconut: { id: "Coconut", name: "Coconut Milk" },
  },
  unitsOfMeasure: {
    g: { id: "g", name: "g", longName: "gram" },
    oz: { id: "oz", name: "oz", longName: "ounces" },
    Tbsp: { id: "Tbsp", name: "Tbsp", longName: "tablespoon" },
    tsp: { id: "tsp", name: "tsp", longName: "teaspoon" },
  },
  flavorings: {
    Chocolate: { id: "Chocolate", name: "Chocolate Sauce" },
    Vanilla: { id: "Vanilla", name: "Vanilla Syrup" },
  },
  sweeteners: {
    Cane: { id: "Cane", name: "Cane Sugar" },
    Classic: { id: "Classic", name: "Classic Syrup" },
    Honey: { id: "Honey", name: "Honey" },
    Splenda: { id: "Splenda", name: "Splenda" },
    SweetNLow: { id: "SweetNLow", name: "Sweet'N Low" },
    Equal: { id: "Equal", name: "Equal" },
    Stevia: { id: "Stevia", name: "Stevia" },
  },
  drinkTypes: {
    Coffee: {
      id: "Coffee",
      name: "Coffee",
      preparationTypes: [
        "Aero",
        "Espresso",
        "FrenchPress",
        "Instant",
        "Stovetop",
        "Chemex",
        "Cone",
        "Drip",
      ],
      variants: ["Espresso", "Dark", "French", "Decaf"],
    },
    Tea: {
      id: "Tea",
      name: "Tea",
      preparationTypes: ["TeaBag", "LooseLeaf"],
      variants: [
        "Black",
        "Green",
        "Oolong",
        "Matcha",
        "Herbal",
        "White",
        "Blend",
      ],
    },
  },
};

export default APP_META_DATA;
