import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const Section = ({ children }) => {
  const classes = useStyles();

  return <section className={classes.root}>{children}</section>;
};

export default Section;
