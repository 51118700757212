export const PATH_PARAM_DRINK_ID = ":drinkId";
export const PATH_PARAM_MACHINE_ID = ":machineId";

export const PATH_SIGN_IN = "/sign-in";
export const PATH_SIGN_OUT = "/sign-out";

export const PATH_REGISTER = "/register";
export const PATH_REGISTER_CONFIRM = "/register-confirmation";
export const PATH_REGISTER_CONFIRM_RESEND_CODE =
  "/resend-register-confirmation-code";

export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_FORGOT_PASSWORD_RESEND_CODE = "/resend-forgot-password-code";

export const PATH_DASHBOARD = "/dashboard";
export const PATH_CREATE_DRINK = "/drink";
export const PATH_UPDATE_DRINK = `/drink/${PATH_PARAM_DRINK_ID}/update`;
export const PATH_VIEW_DRINK = `/drink/${PATH_PARAM_DRINK_ID}/view`;
export const PATH_CONSUME_DRINK = `/drink/${PATH_PARAM_DRINK_ID}/consume`;
export const PATH_MANAGE_DRINKS = `/drink/manage`;
export const PATH_DRINK_LOG = "/drink/log";

export const PATH_CREATE_MACHINE = "/machine";
export const PATH_UPDATE_MACHINE = `/machine/${PATH_PARAM_MACHINE_ID}/update`;
export const PATH_DELETE_MACHINE = `/machine/${PATH_PARAM_MACHINE_ID}/delete`;

export const PATH_SETTINGS = "/settings";

export const PATH_COFFEE_BREWING_METHODS = "/coffee-brewing-methods";
export const PATH_TEA_VARIETIES = "/tea-varieties";
