import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { reduxSetFeedbackMessage } from "../store/app-store";
import { consumeDrink } from "../api/api-requests";
import {
  getLocalDay,
  getLocalMonth,
  getLocalYear,
} from "../utils/date-helpers";
import Spinner from "../common/Spinner";
import { PATH_DASHBOARD } from "../paths";

const ConsumeDrink = () => {
  const reduxDispatch = useDispatch();
  const { drinkId } = useParams();
  const [isWorking, setIsWorking] = React.useState(true);
  const [redirect, setRedirect] = React.useState(null);

  React.useEffect(() => {
    const doConsumeDrink = async () => {
      try {
        const consumeData = {
          year: getLocalYear(),
          month: getLocalMonth(),
          day: getLocalDay(),
        };

        await consumeDrink(drinkId, consumeData);
        reduxDispatch(
          reduxSetFeedbackMessage("Consumed drink was registered", false)
        );
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setRedirect(PATH_DASHBOARD);
        setIsWorking(false);
      }
    };

    doConsumeDrink();
  }, [drinkId, reduxDispatch]);

  if (!isWorking && redirect) {
    return <Redirect to={redirect} />;
  }

  return <Spinner />;
};

export default ConsumeDrink;
