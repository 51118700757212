import {
  PATH_REGISTER,
  PATH_REGISTER_CONFIRM,
  PATH_DASHBOARD,
  PATH_CREATE_DRINK,
  PATH_UPDATE_DRINK,
  PATH_DRINK_LOG,
  PATH_VIEW_DRINK,
  PATH_COFFEE_BREWING_METHODS,
  PATH_TEA_VARIETIES,
  PATH_CREATE_MACHINE,
  PATH_UPDATE_MACHINE,
  PATH_MANAGE_DRINKS,
  PATH_CONSUME_DRINK,
} from "./paths";

import Register from "./register/Register";
import RegisterConfirm from "./register/RegisterConfirm";
import Dashboard from "./dashboard/index";
import UpdateDrink from "./drink/UpdateDrink";
import DrinkLog from "./drink/DrinkLog";
import ViewDrink from "./drink/DrinkLog";
import UpdateMachine from "./machine/UpdateMachine";
import ManageDrinks from "./drink/ManageDrinks";
import ConsumeDrink from "./drink/ConsumeDrink";
import CoffeeBrewingMethods from "./pages/CoffeeBrewingMethods";
import TeaVarieties from "./pages/TeaVarieties";

const APP_ROUTES = [
  {
    path: PATH_REGISTER,
    exact: true,
    key: "register",
    protected: false,
    component: Register,
  },
  {
    path: PATH_REGISTER_CONFIRM,
    exact: true,
    key: "register-confirm",
    protected: false,
    component: RegisterConfirm,
  },
  {
    path: PATH_DASHBOARD,
    exact: true,
    key: "dashboard",
    protected: true,
    component: Dashboard,
  },
  {
    path: PATH_CREATE_DRINK,
    exact: true,
    key: "create-drink",
    protected: true,
    component: UpdateDrink,
  },
  {
    path: PATH_UPDATE_DRINK,
    exact: true,
    key: "update-drink",
    protected: true,
    component: UpdateDrink,
  },
  {
    path: PATH_DRINK_LOG,
    exact: true,
    key: "drink-log",
    protected: true,
    component: DrinkLog,
  },
  {
    path: PATH_VIEW_DRINK,
    exact: true,
    key: "view-drink",
    protected: true,
    component: ViewDrink,
  },
  {
    path: PATH_CONSUME_DRINK,
    exact: true,
    key: "consume-drink",
    protected: true,
    component: ConsumeDrink,
  },
  {
    path: PATH_MANAGE_DRINKS,
    exact: true,
    key: "manage-drinks",
    protected: true,
    component: ManageDrinks,
  },
  {
    path: PATH_CREATE_MACHINE,
    exact: true,
    key: "create-machine",
    protected: true,
    component: UpdateMachine,
  },
  {
    path: PATH_UPDATE_MACHINE,
    exact: true,
    key: "update-machine",
    protected: true,
    component: UpdateMachine,
  },

  {
    path: PATH_COFFEE_BREWING_METHODS,
    exact: true,
    key: "coffee-brewing-methods",
    protected: true,
    component: CoffeeBrewingMethods,
  },
  {
    path: PATH_TEA_VARIETIES,
    exact: true,
    key: "tea-varieties",
    protected: true,
    component: TeaVarieties,
  },
];

export default APP_ROUTES;
