import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";

import SectionHeading from "../common/SectionHeading";
import Section from "../common/Section";
import { LinkButton } from "../common/Buttons";

import { PATH_DRINK_LOG } from "../paths";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  statContainer: {
    padding: "16px",
    textAlign: "center",
  },
  mainStat: {
    fontSize: "1.7rem",
    textAlign: "center",
    marginTop: "2px",
    marginBottom: "6px",
  },
  mainStatContainer: {},
  statInnerBlock: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const StatsPanel = ({
  mainHeading,
  box1Stat,
  box1Legend,
  box2Stat,
  box2Legend,
}) => {
  const classes = useStyles();

  return (
    <Section>
      <SectionHeading
        actionButton={
          <LinkButton
            buttonText="Log"
            path={PATH_DRINK_LOG}
            buttonIcon={<FormatListBulletedOutlinedIcon />}
            size="small"
            variant="text"
          />
        }
      >
        {mainHeading}
      </SectionHeading>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper
            variant="outlined"
            component="div"
            className={classes.statContainer}
          >
            <div className={classes.mainStatContainer}>
              <Typography className={classes.mainStat} component="span">
                {box1Stat}
              </Typography>
            </div>
            <Typography variant="caption">{box1Legend}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            variant="outlined"
            component="div"
            className={classes.statContainer}
          >
            <div className={classes.mainStatContainer}>
              <Typography className={classes.mainStat} component="span">
                {box2Stat}
              </Typography>
            </div>
            <Typography variant="caption">{box2Legend}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Section>
  );
};

export default StatsPanel;
