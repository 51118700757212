import React from "react";
import { Link as RouterLink } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import LocalDrinkOutlinedIcon from "@material-ui/icons/LocalDrinkOutlined";
import IconButton from "@material-ui/core/IconButton";

import NoResultsFound from "../common/NoResultsFound";
import { LinkButton } from "../common/Buttons";
import SectionHeading from "../common/SectionHeading";
import Section from "../common/Section";
import { CoffeeAvatar, TeaAvatar } from "../common/Avatars";

import { DRINK_TYPE_COFFEE } from "../constants";
import {
  PATH_CREATE_DRINK,
  PATH_CONSUME_DRINK,
  PATH_PARAM_DRINK_ID,
} from "../paths";

const DrinkListItem = ({ drink }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        {drink.drinkType === DRINK_TYPE_COFFEE ? (
          <CoffeeAvatar />
        ) : (
          <TeaAvatar />
        )}
      </ListItemAvatar>
      <ListItemText primary={drink.name} />
      <ListItemSecondaryAction>
        <IconButton
          component={RouterLink}
          to={PATH_CONSUME_DRINK.replace(PATH_PARAM_DRINK_ID, drink.id)}
        >
          <LocalDrinkOutlinedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const RenderHeading = () => {
  return (
    <SectionHeading
      actionButton={
        <LinkButton
          buttonText="Add"
          path={PATH_CREATE_DRINK}
          buttonIcon={<AddIcon />}
          size="small"
          variant="text"
        />
      }
    >
      My Drinks
    </SectionHeading>
  );
};

const MyDrinks = ({ drinksIdList, drinks }) => {
  const renderDrinksList = () => {
    if (drinksIdList && drinksIdList.length > 0) {
      return (
        <List>
          {drinksIdList.map((drinkId) => {
            return <DrinkListItem drink={drinks[drinkId]} key={drinkId} />;
          })}
        </List>
      );
    }

    return (
      <NoResultsFound>
        No drinks defined.{" "}
        <RouterLink to={PATH_CREATE_DRINK}>Create one now.</RouterLink>
      </NoResultsFound>
    );
  };

  return (
    <Section>
      <RenderHeading />
      <Paper elevation={0} variant="outlined" style={{ padding: "8px" }}>
        {renderDrinksList()}
      </Paper>
    </Section>
  );
};

export default MyDrinks;
