import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";

import { amplify_auth_config, amplify_api_config } from "./aws-config";
import store from "./store/redux-store";
import App from "./App";

Amplify.configure({
  Auth: amplify_auth_config,
  API: amplify_api_config,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);
