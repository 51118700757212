import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const queryFormat = "YYYY-MM-DDTHH:mm:ss";

export function getLocalDate() {
  return dayjs().format("YYYY-MM-DD");
}

export function getLocalYear() {
  return dayjs().year();
}

export function getLocalMonth() {
  return dayjs().month();
}

export function getLocalDay() {
  return dayjs().day();
}

export function getUtcStartOfDay() {
  const sod = dayjs().startOf("day");
  const utcStartOfDay = dayjs(sod.format()).utc();
  return utcStartOfDay.format(queryFormat);
}

export function getUtcStartOfWeek() {
  const sow = dayjs().startOf("week");
  const utcStartOfWeek = dayjs(sow.format()).utc();
  return utcStartOfWeek.format(queryFormat);
}

export function getUtcStartOfMonth() {
  const som = dayjs().startOf("month");
  const utcStartOfMonth = dayjs(som.format()).utc();
  return utcStartOfMonth.format(queryFormat);
}

export function utcAsLocalDateTime(dt, displayFormat = "YYYY-MM-DD HH:mm") {
  const utcDateTime = dayjs(dt);
  return utcDateTime.local().format(displayFormat);
}

export function getUserTimezone() {
  return dayjs.tz.guess();
}
