import React, { useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { reduxSetFeedbackMessage } from "../store/app-store";
import { PATH_REGISTER_CONFIRM, PATH_SIGN_IN } from "../paths";
import { register } from "../utils/auth";
import { getShortUUID } from "../utils/general-helpers";

import RegisterForm from "./RegisterForm";
import PageHeading from "../common/PageHeading";

const Register = (props) => {
  const reduxDispatch = useDispatch();
  const [isWorking, setIsWorking] = useState(null);
  const [redirect, setRedirect] = useState(null);

  const onRegisterSubmit = ({ name, email, password }) => {
    const doRegister = async () => {
      try {
        setIsWorking(true);
        const tempAccountId = getShortUUID();
        const user = register(tempAccountId, name, email, password);
        if (user) {
          reduxDispatch(
            reduxSetFeedbackMessage("Registration successful.", false)
          );
          setRedirect(`${PATH_REGISTER_CONFIRM}?email=${email}`);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsWorking(false);
      }
    };
    doRegister();
  };

  if (!isWorking && redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <PageHeading>Register</PageHeading>
      <RegisterForm onSubmit={onRegisterSubmit} isWorking={isWorking} />
      <Divider style={{ marginBottom: "16px" }} />
      <Typography component="p" variant="body2" gutterBottom>
        <Link to={PATH_SIGN_IN} component={RouterLink}>
          Have an account? Sign In.
        </Link>
      </Typography>
      <Typography component="p" variant="body2" gutterBottom>
        <Link to={PATH_REGISTER_CONFIRM} component={RouterLink}>
          Have confirmation code?
        </Link>
      </Typography>
    </>
  );
};

export default Register;
