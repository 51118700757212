import React from "react";
import { useDispatch } from "react-redux";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemSecondaryAction } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import DeleteDialog from "../common/DeleteDialog";
import Spinner from "../common/Spinner";
import PageHeading from "../common/PageHeading";
import NoResultsFound from "../common/NoResultsFound";
import { fetchDrinkLog, deleteConsumedDrink } from "../api/api-requests";
import { reduxSetFeedbackMessage } from "../store/app-store";
import { getUtcStartOfDay } from "../utils/date-helpers";
import { getUtcStartOfWeek, getUtcStartOfMonth } from "../utils/date-helpers";
import { ActionButton } from "../common/Buttons";
import {
  PERIOD_THIS_MONTH,
  PERIOD_THIS_WEEK,
  PERIOD_TODAY,
} from "../constants";
import { utcAsLocalDateTime } from "../utils/date-helpers.js";

function parseDrinkData(drinkData) {
  if (!drinkData) {
    return null;
  }

  const dateArray = [];
  const data = {};

  drinkData.forEach((item) => {
    const dateLocal = utcAsLocalDateTime(item.createdOn, "YYYY-MM-DD");
    if (dateArray.includes(dateLocal)) {
      data[dateLocal].drinks.push(item);
    } else {
      dateArray.push(dateLocal);
      data[dateLocal] = {
        displayDate: utcAsLocalDateTime(item.createdOn, "ddd, MMM D"),
        drinks: [item],
      };
    }
  });

  return {
    dates: dateArray,
    data: data,
  };
}

const DrinkGroup = ({ groupData, onDeleteClick }) => {
  return (
    <>
      <Typography variant="h4">{groupData.displayDate}</Typography>
      <Paper elevation={0} variant="outlined">
        <List>
          {groupData.drinks.map((item) => {
            return (
              <DrinkItem
                item={item}
                key={item.createdOn}
                onDeleteClick={onDeleteClick}
              />
            );
          })}
        </List>
      </Paper>
    </>
  );
};

const DrinkItem = ({ item, onDeleteClick }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <>{utcAsLocalDateTime(item.createdOn, "H:mm")}</>
      </ListItemAvatar>
      <ListItemText primary={item.drinkConfig.name} />
      <ListItemSecondaryAction>
        <IconButton onClick={() => onDeleteClick(item.id)}>
          <DeleteOutlinedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const DrinkLog = () => {
  const reduxDispatch = useDispatch();
  const [drinks, setDrinks] = React.useState(null);
  const [isWorking, setIsWorking] = React.useState(true);
  const [period, setPeriod] = React.useState(PERIOD_TODAY);
  const [pageHeading, setPageHeading] = React.useState("Today");
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [refreshToggle, setRefreshToggle] = React.useState(0);

  const onDeleteClick = (itemId) => {
    console.log(itemId);
    setItemToDelete(itemId);
  };

  const onOkToDeleteClick = () => {
    const doDelete = async () => {
      try {
        await deleteConsumedDrink(itemToDelete);
        setItemToDelete(null);
        setRefreshToggle(!refreshToggle);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      }
    };

    doDelete();
  };

  const onCancelDeleteClick = () => {
    setItemToDelete(null);
  };

  React.useEffect(() => {
    const doFetch = async (pageHeading, startDate, endDate) => {
      try {
        setIsWorking(true);
        const result = await fetchDrinkLog(startDate, endDate);
        setPageHeading(pageHeading);
        setDrinks(parseDrinkData(result));
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    switch (period) {
      case PERIOD_THIS_WEEK:
        doFetch("This Week", getUtcStartOfWeek());
        break;

      case PERIOD_THIS_MONTH:
        doFetch("This Month", getUtcStartOfMonth());
        break;

      default:
        doFetch("Today", getUtcStartOfDay());
    }
  }, [reduxDispatch, period, refreshToggle]);

  const renderDrinkLog = () => {
    if (drinks) {
      return (
        drinks && (
          <List>
            {drinks.dates.map((currentDate) => {
              return (
                <DrinkGroup
                  groupData={drinks.data[currentDate]}
                  key={currentDate}
                  onDeleteClick={onDeleteClick}
                />
              );
            })}
          </List>
        )
      );
    }

    return <NoResultsFound>The drink log is empty.</NoResultsFound>;
  };

  if (!isWorking) {
    return (
      <>
        <PageHeading>Drink Log {pageHeading}</PageHeading>
        <div>
          <ActionButton
            buttonText="Today"
            onClickEvent={() => setPeriod(PERIOD_TODAY)}
          />
          <ActionButton
            buttonText="This Week"
            onClickEvent={() => setPeriod(PERIOD_THIS_WEEK)}
          />
          <ActionButton
            buttonText="This Month"
            onClickEvent={() => setPeriod(PERIOD_THIS_MONTH)}
          />
        </div>

        {renderDrinkLog()}

        {itemToDelete && (
          <DeleteDialog
            title={"Delete Consumed Drink"}
            confirmButtonText="Yes, Delete"
            onConfirm={onOkToDeleteClick}
            cancelButtonText="Cancel"
            onCancel={onCancelDeleteClick}
          >
            Are you sure you want to delete this consumed drink?
          </DeleteDialog>
        )}
      </>
    );
  }

  return <Spinner />;
};

export default DrinkLog;
