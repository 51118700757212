import React from "react";
import { useForm, Controller } from "react-hook-form";

import TextField from "@material-ui/core/TextField";

import { SubmitButton } from "../common/Buttons";

const SignInForm = (props) => {
  const { onSubmit } = props;
  const { handleSubmit, control } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: "You must enter your email" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Email"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="email"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{ required: "You must enter your password" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            label="Password"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="password"
          />
        )}
      />
      <div style={{ textAlign: "center", margin: "16px 0" }}>
        <SubmitButton buttonText="Sign In" />
      </div>
    </form>
  );
};

export default SignInForm;
