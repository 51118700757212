import ImageContainer from "../common/ImageContainer";

const TeaBrewingMethods = () => {
  return (
    <ImageContainer
      attribution="Image courtesy of WebstaurantStoreBlog. Click the image to visit the article."
      imageAltText="The Best Way to Make Tea"
      imageLinkUrl="https://www.webstaurantstore.com/blog/1784/best-way-to-make-tea.html"
      imageUrl="https://cdnimg.webstaurantstore.com/uploads/blog/2017/1/teainfographic_lrg.jpg"
    />
  );
};

export default TeaBrewingMethods;
