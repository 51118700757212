import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import Header from "./common/Header";
import Wrapper from "./common/Wrapper";
import Spinner from "./common/Spinner";
import FeedbackMessageController from "./common/FeedbackMessageController";
import RenderRoutes from "./common/RenderRoutes";

import { APP_THEME } from "./utils/app-theme";
import { checkSignIn, signOut } from "./utils/auth";
import { PATH_SIGN_IN } from "./paths";
import APP_ROUTES from "./routes-config";

const App = () => {
  const reduxDispatch = useDispatch();

  const [isWorking, setIsWorking] = React.useState(true);
  const [isSignedIn, setIsSignedIn] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState(null);

  React.useEffect(() => {
    const checkIsSignedIn = async () => {
      const result = await checkSignIn();
      setIsSignedIn(result.isSignedIn);
      setCurrentUser(result.user);
      setIsWorking(false);
    };
    checkIsSignedIn();
  }, [reduxDispatch]);

  const onSignOutClick = () => {
    const doSignOut = async () => {
      signOut();
      setIsSignedIn(false);
      setCurrentUser(null);
    };
    doSignOut();
  };

  if (!isWorking) {
    return (
      <ThemeProvider theme={APP_THEME}>
        <Router>
          <Header
            isSignedIn={isSignedIn}
            onSignOutClick={onSignOutClick}
            currentUser={currentUser}
          />
          <Wrapper>
            <FeedbackMessageController />
            <RenderRoutes
              routes={APP_ROUTES}
              defaultRedirectPath={PATH_SIGN_IN}
              isSignedIn={isSignedIn}
              setIsSignedIn={setIsSignedIn}
              setCurrentUser={setCurrentUser}
            />
          </Wrapper>
        </Router>
      </ThemeProvider>
    );
  }

  return <Spinner />;
};

export default App;
