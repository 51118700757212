import React from "react";

import { pathPrefixDashboard } from "../api/api-requests";
import useFetchHook from "../api/useFetchHook";
import { getUtcStartOfDay } from "../utils/date-helpers";
// import { reduxSetFeedbackMessage } from "../store/app-store";
import StatsPanel from "./StatsPanel";
import MyDrinks from "./MyDrinks";
import MyMachines from "./MyMachines";
import Spinner from "../common/Spinner";
import NetworkError from "../common/NetworkError";
import { STATE_FINISHED, STATE_ERROR } from "../store/store-constants";

function getDashboardQueryParams() {
  return { startDate: getUtcStartOfDay() };
}

const Dashboard = () => {
  const { status: workingState, data: dashboardData } = useFetchHook(
    pathPrefixDashboard,
    getDashboardQueryParams
  );

  if (workingState === STATE_FINISHED) {
    return (
      <>
        <StatsPanel
          mainHeading="Drinks Today"
          box1Stat={dashboardData.todaysStats.totalDrinksConsumed}
          box1Legend="consumed"
          box2Stat={dashboardData.todaysStats.totalCaffeineConsumedMg}
          box2Legend="mg caffeine"
        />
        <MyDrinks
          drinks={dashboardData.drinks.drinks}
          drinksIdList={dashboardData.drinks.orderedIdList}
        />
        <MyMachines
          machines={dashboardData.machines.machines}
          machineIdList={dashboardData.machines.orderedIdList}
        />
      </>
    );
  }

  if (workingState === STATE_ERROR) {
    return <NetworkError />;
  }

  return <Spinner />;
};

export default Dashboard;
