import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { API } from "aws-amplify";

import { apiName } from "./api-requests";
import { reduxSetFeedbackMessage } from "../store/app-store";
import {
  STATE_ERROR,
  STATE_WORKING,
  STATE_FINISHED,
} from "../store/store-constants";

const initialState = {
  status: STATE_WORKING,
  error: null,
  data: [],
};

// All API hooks will use the same reducer to provide consistent feedback to callers
const apiReducer = (state, action) => {
  switch (action.type) {
    case STATE_FINISHED:
      return { ...state, status: STATE_FINISHED, data: action.payload };
    case STATE_ERROR:
      return { ...state, status: STATE_ERROR, error: action.payload };
    default:
      return state;
  }
};

const useFetchHook = (url, queryParamsFunction = null) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(apiReducer, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;

    const doFetch = async () => {
      try {
        const result = await API.get(apiName, url, {
          queryStringParameters: queryParamsFunction
            ? queryParamsFunction()
            : null,
        });
        if (cancelRequest) return;
        dispatch({ type: STATE_FINISHED, payload: result });
      } catch (err) {
        if (cancelRequest) return;
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
        dispatch({ type: STATE_ERROR, payload: err.message });
      }
    };

    doFetch();

    // avoid unmounted operation issues
    return function cleanup() {
      cancelRequest = true;
    };
  }, [reduxDispatch, url, queryParamsFunction]);

  return state;
};

export default useFetchHook;
