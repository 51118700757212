import React from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import NoResultsFound from "../common/NoResultsFound";
import PageHeading from "../common/PageHeading";
import Spinner from "../common/Spinner";
import DrinkCard from "../common/DrinkCard";
import { fetchAllDrinks } from "../api/api-requests";
import { reduxSetFeedbackMessage } from "../store/app-store";
import { PATH_CREATE_DRINK } from "../paths";

const ManageDrinks = () => {
  const reduxDispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(true);
  const [myDrinks, setMyDrinks] = React.useState(null);

  React.useEffect(() => {
    const doFetchData = async () => {
      try {
        setIsLoading(true);
        const drinks = await fetchAllDrinks();
        setMyDrinks(drinks);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsLoading(false);
      }
    };

    doFetchData();
  }, [reduxDispatch]);

  const renderDrinksList = () => {
    if (myDrinks.orderedIdList && myDrinks.orderedIdList.length > 0) {
      return myDrinks.orderedIdList.map((drinkId) => {
        return (
          <DrinkCard
            drink={myDrinks.drinks[drinkId]}
            key={drinkId}
            isForManage={true}
          />
        );
      });
    }

    return (
      <NoResultsFound>
        No drinks defined.{" "}
        <RouterLink to={PATH_CREATE_DRINK}>Create one now.</RouterLink>
      </NoResultsFound>
    );
  };

  if (!isLoading) {
    return (
      <>
        <PageHeading>Manage My Drinks</PageHeading>
        {renderDrinksList()}
      </>
    );
  }

  return <Spinner />;
};

export default ManageDrinks;
