import Avatar from "@material-ui/core/Avatar";
import LocalCafeOutlinedIcon from "@material-ui/icons/LocalCafeOutlined";
import EmojiFoodBeverageOutlinedIcon from "@material-ui/icons/EmojiFoodBeverageOutlined";
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    margin: 0,
  },
  primaryLight: {
    backgroundColor: theme.palette.primary.light,
    color: "#FFFFFF",
  },
  secondaryDark: {
    backgroundColor: theme.palette.secondary.dark,
    color: "#FFFFFF",
  },
  secondaryLight: {
    backgroundColor: theme.palette.secondary.light,
    color: "#FFFFFF",
  },
  small: {
    width: "32px",
    height: "32px",
  },
}));

export const CoffeeAvatar = () => {
  const classes = useStyles();

  return (
    <Avatar className={`${classes.secondaryDark} ${classes.small}`}>
      <LocalCafeOutlinedIcon fontSize="small" />
    </Avatar>
  );
};

export const TeaAvatar = () => {
  const classes = useStyles();

  return (
    <Avatar className={`${classes.secondaryLight} ${classes.small}`}>
      <EmojiFoodBeverageOutlinedIcon fontSize="small" />
    </Avatar>
  );
};

export const MachineAvatar = () => {
  const classes = useStyles();

  return (
    <Avatar className={`${classes.primaryLight} ${classes.small}`}>
      <TouchAppOutlinedIcon fontSize="small" />
    </Avatar>
  );
};
