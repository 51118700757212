import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ErrorMessage = ({ messageText, onDismissClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.alert}>
      <Alert onClose={() => onDismissClick()} severity="error">
        {messageText}
      </Alert>
    </div>
  );
};

export default ErrorMessage;
