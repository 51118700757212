import React from "react";
import { useForm, Controller } from "react-hook-form";

import TextField from "@material-ui/core/TextField";

import { SubmitButton } from "../common/Buttons";

const RegisterConfirmForm = (props) => {
  const { onSubmit, isWorking, currentEmail } = props;

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({ mode: "onChange" });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        defaultValue={currentEmail || ""}
        rules={{ required: "Please enter your email" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Email"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
            autoComplete="email"
            autoFocus
          />
        )}
      />
      <Controller
        name="confirmCode"
        control={control}
        defaultValue=""
        rules={{ required: "Please enter the confirmation code" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Confirmation Code"
            variant="filled"
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            size="small"
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <div style={{ textAlign: "center", margin: "16px 0" }}>
        <SubmitButton
          buttonText="Confirm Registration"
          isWorking={isWorking}
          isDisabled={!isDirty || !isValid || isWorking}
        />
      </div>
    </form>
  );
};

export default RegisterConfirmForm;
