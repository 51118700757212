import React from "react";
import { useForm, Controller } from "react-hook-form";

import TextField from "@material-ui/core/TextField";

import { LinkButton, SubmitButton, ButtonGroup } from "../common/Buttons";
import { PATH_DASHBOARD } from "../paths";

const UpdateMachineForm = ({ onSubmit, defaultValues }) => {
  const { control, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Controller
        name="name"
        control={control}
        defaultValue={defaultValues ? defaultValues.name : ""}
        render={({ field }) => (
          <TextField
            {...field}
            label="Name"
            size="small"
            variant="filled"
            margin="normal"
            helperText="Provide a friendly name for the machine (this will be displayed in the app)"
            fullWidth
            required
            autoFocus
          />
        )}
      />

      <Controller
        name="manufacturer"
        control={control}
        defaultValue={defaultValues ? defaultValues.manufacturer : ""}
        render={({ field }) => (
          <TextField
            {...field}
            label="Manufacturer Name"
            size="small"
            margin="normal"
            variant="filled"
            helperText="e.g. Breville, Nespresso, Keurig etc."
            fullWidth
          />
        )}
      />

      <Controller
        name="model"
        control={control}
        defaultValue={defaultValues ? defaultValues.model : ""}
        render={({ field }) => (
          <TextField
            {...field}
            label="Model Number/Name"
            size="small"
            margin="normal"
            variant="filled"
            helperText="e.g. Barista Pro"
            fullWidth
          />
        )}
      />

      <Controller
        name="datePurchased"
        control={control}
        defaultValue={
          defaultValues
            ? defaultValues.purchasedOn
              ? defaultValues.purchasedOn
              : ""
            : ""
        }
        render={({ field }) => (
          <TextField
            {...field}
            label="Date Purchased"
            size="small"
            margin="normal"
            variant="filled"
            helperText="When did you purchase the machine?"
            fullWidth
          />
        )}
      />

      <ButtonGroup>
        <LinkButton buttonText="Cancel" path={PATH_DASHBOARD} variant="text" />
        <SubmitButton />
      </ButtonGroup>
    </form>
  );
};

export default UpdateMachineForm;
