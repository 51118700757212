import Typography from "@material-ui/core/Typography";

const NoResultsFound = ({ children }) => {
  return (
    <Typography variant="body2" component="div" style={{ margin: "16px" }}>
      {children}
    </Typography>
  );
};

export default NoResultsFound;
