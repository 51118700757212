import Typography from "@material-ui/core/Typography";

const SectionHeading = ({ actionButton, children }) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      {actionButton && (
        <div style={{ float: "right", width: "100" }}>{actionButton}</div>
      )}
      <Typography component="h3" variant="h3">
        {children}
      </Typography>
    </div>
  );
};

export default SectionHeading;
