import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import PageHeading from "../common/PageHeading";
import Spinner from "../common/Spinner";
import UpdateMachineForm from "./UpdateMachineForm";

// import { reduxAddMachine, reduxUpdateMachine } from "./machine-store";
import {
  fetchMachine,
  updateMachine,
  createMachine,
} from "../api/api-requests";
import { PATH_DASHBOARD } from "../paths";
import { reduxSetFeedbackMessage } from "../store/app-store";

const UpdateMachine = () => {
  const reduxDispatch = useDispatch();
  const { machineId } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isWorking, setIsWorking] = React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const [defaultFormValues, setDefaultFormValues] = React.useState(null);

  React.useEffect(() => {
    const doFetchMachine = async () => {
      try {
        const machine = await fetchMachine(machineId);
        setDefaultFormValues(machine);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsLoading(false);
      }
    };

    if (machineId) {
      doFetchMachine();
    } else {
      setIsLoading(false);
    }
  }, [machineId, reduxDispatch]);

  const onAddNewSubmit = (formData) => {
    const doAddNew = async () => {
      try {
        setIsWorking(true);
        await createMachine(formData);
        reduxDispatch(
          reduxSetFeedbackMessage("Machine added successfully", false)
        );
        setRedirect(PATH_DASHBOARD);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    doAddNew();
  };

  const onUpdateSubmit = (formData) => {
    const doUpdate = async () => {
      try {
        setIsWorking(true);
        await updateMachine(machineId, formData);
        reduxDispatch(
          reduxSetFeedbackMessage("Machine updated successfully", false)
        );
        setRedirect(PATH_DASHBOARD);
      } catch (err) {
        reduxDispatch(reduxSetFeedbackMessage(err.message, true));
      } finally {
        setIsWorking(false);
      }
    };

    doUpdate();
  };

  if (!isWorking && redirect) {
    return <Redirect to={redirect} />;
  }

  if (!isLoading) {
    return (
      <>
        <PageHeading>{machineId ? "Update" : "Add"} Coffee Machine</PageHeading>
        <UpdateMachineForm
          onSubmit={machineId ? onUpdateSubmit : onAddNewSubmit}
          defaultValues={defaultFormValues}
        />
      </>
    );
  }

  return <Spinner />;
};

export default UpdateMachine;
