import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import NoResultsFound from "../common/NoResultsFound";
import { LinkButton } from "../common/Buttons";
import SectionHeading from "../common/SectionHeading";
import { MachineAvatar } from "../common/Avatars";
import Section from "../common/Section";

import {
  PATH_CREATE_MACHINE,
  PATH_PARAM_MACHINE_ID,
  PATH_UPDATE_MACHINE,
} from "../paths";

const MachineItem = ({ machine }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <MachineAvatar />
      </ListItemAvatar>
      <ListItemText primary={machine.name} />
      <ListItemSecondaryAction>
        <IconButton
          component={RouterLink}
          to={PATH_UPDATE_MACHINE.replace(PATH_PARAM_MACHINE_ID, machine.id)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const RenderHeading = () => {
  return (
    <SectionHeading
      actionButton={
        <LinkButton
          buttonText="Add"
          path={PATH_CREATE_MACHINE}
          buttonIcon={<AddIcon />}
          size="small"
          variant="text"
        />
      }
    >
      My Machines
    </SectionHeading>
  );
};

const MyMachines = ({ machineIdList, machines }) => {
  const renderMachineList = () => {
    if (machineIdList && machineIdList.length > 0) {
      return (
        <List>
          {machineIdList.map((machineId) => {
            return (
              <MachineItem machine={machines[machineId]} key={machineId} />
            );
          })}
        </List>
      );
    }

    return (
      <NoResultsFound>
        No machines defined.{" "}
        <RouterLink to={PATH_CREATE_MACHINE}>Create one now.</RouterLink>
      </NoResultsFound>
    );
  };

  return (
    <Section>
      <RenderHeading />
      <Paper elevation={0} variant="outlined" style={{ padding: "8px" }}>
        {renderMachineList()}
      </Paper>
    </Section>
  );
};

export default MyMachines;
